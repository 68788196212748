



























































































































import {
  DtoNetworkInformerSign,
  FormRules,
  reqArea,
  reqValidateCodeSign,
  VoAreaList,
  reqNetworkInformerSignSubmit
} from "@/api";
import { Form } from "element-ui";
import { CascaderProps } from "node_modules/element-ui/types/cascader-panel";
import { Component, Vue } from "vue-property-decorator";
import { PoliticCountenance, Nation, Education } from "@/types/enum";

@Component({
  name: "network-informer-sign",
  components: {}
})
export default class extends Vue {
  form: DtoNetworkInformerSign = {};
  disabled = false;
  count = 60;
  picker = {
    disabledDate(time: any) {
      return time.getTime() > Date.now();
    }
  };

  politicCountenance = PoliticCountenance;
  nationList = Nation;
  educationList = Education;


  get cascaderProps(): CascaderProps<string, VoAreaList> {
    return {
      lazy: true,
      label: "name",
      value: "name",
      lazyLoad: async (node, resolve) => {
        const { data: nodes } = await reqArea("500100");
        resolve(
          nodes.map(i => {
            return {
              ...i,
              leaf: true
            };
          })
        );
      }
    };
  }

  get form1Rules(): FormRules<DtoNetworkInformerSign> {
    return {
      name: [
        {
          required: true,
          message: `请输入真实姓名`,
          trigger: "change"
        }
      ],
      sex: [
        {
          required: true,
          message: `请选择性别`,
          trigger: "change"
        }
      ],
      birthday: [
        {
          required: true,
          message: `请选择出生年月`,
          trigger: "blur"
        }
      ],
      politicalStatus: [
        {
          required: true,
          message: `请选择政治面貌`,
          trigger: "change"
        }
      ],
      nation: [
        {
          required: true,
          message: `请选择民族`,
          trigger: "change"
        }
      ],
      education: [
        {
          required: true,
          message: `请选择学历`,
          trigger: "change"
        }
      ],
      workUnit: [
        {
          required: true,
          message: `请输入您的工作单位`,
          trigger: "blur"
        }
      ],
      validateCode: [
        {
          required: true,
          message: `请输入图片验证码`,
          trigger: "blur"
        }
      ],
      district: [
        {
          required: true,
          message: `请选择您所在的区县`,
          trigger: "change",
          validator: (rule, value, callback) => {
            if (!this.form.district?.length) {
              callback(new Error("请选择您所在的区县"));
            } else {
              callback();
            }
          }
        }
      ],
      linkPhone: [
        {
          required: true,
          message: `请输入您的联系方式（手机、座机、电子邮箱）`,
          trigger: "blur"
        },
        { validator: this.validQC, trigger: "blur" }
      ]
    };
  }

  areaChoose(value: string[]) {
    if (value.length) {
      this.form.district = value;
    }
  }
  formValidHandler() {
    return (this.$refs.form1 as Form).validate();
  }
  validQC(rule: any, value: any, callback: any){
    if (value) {
      if (/[\u4E00-\u9FA5]/g.test(value)) {
        callback(new Error("不能输入汉字"));
      } else {
        // 验证通过
        callback();
      }
      callback();
    }
  }
  picValidRequesting = false;
  picValidCode = "";

  get picValidImage() {
    return `data:image/png;base64,${this.picValidCode}`;
  }

  async picInitValid() {
    try {
      this.picValidRequesting = true;
      const { data } = await reqValidateCodeSign();
      this.picValidCode = data;
    } finally {
      this.picValidRequesting = false;
    }
  }

  listenForm() {
    this.form = {
      linkPhone: "",
      district: [],
      birthday: "",
      education: "",
      nation: "",
      sex: "",
      name: "",
      validateCode: "",
      workUnit: "",
      politicalStatus: ""
    };
    this.$nextTick(() => {
      (this.$refs.form1 as Form).clearValidate();
    });
  }

  mounted() {
    this.picInitValid();
  }

  async submit() {
    try {
      await (this.$refs.form1 as Form).validate();
    } catch (error) {
      return this.$message.error(`请检查输入信息是否正确`);
    }
    try {
      const res = await reqNetworkInformerSignSubmit(this.form);
      if (res.status === 200) {
        this.$router.back();
        this.$message.success(res.data);
      }
    } catch (error) {
      const err = error as any;
      this.$message.error(err.response.data.message);
    }
  }
}
